import { Component, OnInit } from '@angular/core';
import { ProjectI } from 'src/app/schemas/projectI';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  public projectList: ProjectI[]
  constructor() { }

  ngOnInit() {
    this.projectList = [
      {
        title: "Fridge Monitor System",
        description: "For my capstone project, I had to develop a product from scratch including its prototype." +
        " I developed a device that goes inside of the fridge and help the user better monitor the content and behavior of it, " + 
        "thus helping reduce food waste. I developed both the prototype and the mobile application. The prototype has a temperature sensor, a camera and two servo motors. " + 
        "The temperature sensors work to keep track of the temperature of the fridge and alert the user of any odd change. The combination of the motors and camera give the " +
        "user a view of the inside of their fridge that can be move remotely from the mobile app for an even better view. " +
        "The camera also let the user scan products to provide an inventory list.",
        picturePath: ["/assets/images/projects/fridgeMonitorSystem.png"]
    },
    {
        title: "Capability Tool",
        description:"As part of my co-op experience, I was assigned the task to develop an application that will help department managers keep track of " +
        "how the employees are doing in the basic competencies needed for the department to run smoothly. The tool has a structure that you " + 
        "can create competencies for the whole department or some targeting an specific area. All the data entered for a depertament is read by " +
        "Tableau and displayed to the user in an easy to understand matter. The hardest aspect of this application was the security. Salesforce " + 
        "normally manages its application security with groups, meaning if I want a group of users to edit and view a certain amount of data all I " +
        "have to do is create a gropu with those users. The problem with going this route was that with an application like this that was scope to be use by " +
        "multiple departments, it meant to create a group for each individual department and have does up to date at all time. Instead we decided to go our own " +
        "route and create a customized security that fit the need of the company and now the department owner gets to decides who sees and edits the organization records. " +
        "This application was fully developed and is now live.",
        picturePath: [ "/assets/images/projects/salesforce.png",
            "/assets/images/projects/tableau-logo.png"
        ]
    },
    {
        title: "Reliability Metrics Dashboard",
        description:"The objective of this project was to create a data analytics tool that calculates the MTBF " + 
        "(Mean time between failures) and maintenance cost. The tool requieres a query that analyzes the data and calculates it to " +
        "get the result needed to comply with the monthly reliability reports. Tableau reads the raw data from the maintenance data server and " + 
        "reports the calculated data in dynamic dashboards where the user can change parameters and filters.",
        picturePath: ["/assets/images/projects/tableau-logo.png"]
    },
    {
        title: "Light Dress",
        description:"​This project is an official Hack Turabo Project along with the Design school of Universidad del Turabo " +
        "('Escuela Internacional de Diseño y Arquitectura de la Universidad del Turabo' ). The project consist on programming a " +
        "fully designed dress with LED lights to fit the designers vision. The dress has LED lights connected in a matrix form around the " + 
        "whole dress and two Arduino Board Mega that will be program by the Hack Turabo Team. Dress designed by Cristiano Carciani.",
        picturePath: ["/assets/images/projects/light_dress.jpg"]
    },
    {
        title: "Traffic Light System",
        description:"The final project for the Digital Logic Design class consisted on designing a traffic light system for " +
        "an intersection road, the changing lights were to be controlled with a timer of 30 seconds the green light, 5 seconds the " + 
        "yellow one and 2 seconds the red one. If 10 or more care pass through on the 30 seconds of the green light the timer " +
        "adds 30 more seconds to the current green light, this addition can’t happen more than three times. The ten steps of " + 
        "designing a synchronous state machine was use to build this. The materials use were a D flip-flop, a 74163 gate as our counter, " +
        "LED lights and logic gates. This project was completed along side my partner Pedro Anibarro.",
        picturePath: ["/assets/images/projects/digitalproject.jpg"]
    },
    {
        title: "Maze Runner",
        description:"​​​The objective of this project was to use the knowledge learnt in the Electronic class by choosing proposing your " +
        "own project idea. We decided to do an autonomous car that will escape a maze by itself. This project used an Arduino Uno, " +
        "three proximity sensors, a gyroscope, a tank chasis and two H-Bridge. This project was done along side my partner Pedro Anibarro.",
        picturePath: ["/assets/images/projects/mazerunner.png"]
    }
    ]
  }

}
