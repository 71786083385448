import { Component, OnInit } from '@angular/core';
import { ResumeI, EducationI } from 'src/app/schemas/resumeI';
import { ProjectI } from 'src/app/schemas/projectI';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  public experienceList: ResumeI[];
  public education: EducationI;
  public extraList: ProjectI[];
  public languages: string[];
  constructor() { }

  ngOnInit() {
    this.experienceList = [
      {
        company: "Accenture",
        positionTitle: "Sr. Technology Analyst",
        timePeriod: "December 2019 - Present",
        description: "Works as a full stack developer to further develop and maintain client\'s application. " + 
        "My tasks also include creating unit tests, providing production support and training new developers." 
      },
      {
        company: "Accenture",
        positionTitle: "Technology Analyst",
        timePeriod: "July 2018 - November 2019",
        description: "Worked as an Angular upgrade developer for a client. Along side with a scrum-based team of developers " + 
        "to upgrade client’s Angular JS application to Angular 2+. My tasks included most of the front-end aspect of the " + 
        "application using capacitors and ionic for a cross platform application. "
      },
      {
        company: "Forward Learning",
        positionTitle: "Programming and Robotics Assistant",
        timePeriod: "August 2017 - June 2018",
        description: "Worked as an assistant to teacher interested in teaching programming and robotis courses. Assisted them by providing presentations," +
          " plans and project ideas for each class. Also by being present in the class to help with any doubt the students might had."
      },
      {
        company: "Lilly Del Caribe",
        positionTitle: "IT Co-op Student",
        timePeriod: "August 2016- July 2017",
        description: "Worked as a part of the IT team of Lilly del Caribe. Developed Lilly del Caribe's first cloud based application using " +
          "a combination of Salesforce and Tableau. Configured a data analytics tool and display the results using Tableau. Directly assisted clients on their business need."
      },
      {
        company: "Saturday Research Academy",
        positionTitle: "Robotics Mentor",
        timePeriod: "August 2015 - December 2015",
        description: "Worked as a mentor for the computer science department of the Saturday Research Academy. Assisted pre-college students with their research in robotics and drones technology and directed a C++ class. Supervised the students research and their poster presentation."
      },
      {
        company: "Caguas Municipal Legislative",
        positionTitle: "Internship Student",
        timePeriod: "August 2014 - December 2014",
        description: "Assisted the office with technology related work. Developed a project proposal to help Caguas community."
      },
    ];
    this.education = {
      university: "Universidad Del Turabo",
      timePeriod: "August 2012 - May 2018",
      description: "Accepted at the Universidad del Turabo Computer" +
        " Engineering program and at the Universidad Honor Program. " +
        "Graduated with a GPA of 3.17",
      gradDate: "May 2018"
    };
    this.extraList = [
      {
        title: "Hack Turabo Media Manager",
        description: "In charge of managing the website " +
          "and creating promotional material of the student associations Hack Turabo.",
        timePeriod: "January 2015- May 2017"
      },
      {
        title: "Android Foundation Bootcamp",
        description: "Received a scholarship to attend the Android Foundation " +
          "Bootcamp provided by Paradise Company. The bootcamp consisted of 5 day " +
          "intensive Android training.",
        timePeriod: "April 2016- May 2016"
      },
      {
        title: "SHPE Turabo Planning and Logistic Vice President",
        description: "In charge of planning the activities calendar " +
          "and organized the needed equipment for the University chapter.",
        timePeriod: "June 2014 - May 2015"
      }
    ];
    
    this.languages = [
      "Adobe Photoshop",
      "Adobe Premier",
      "Android Studios",
      "Angular 2+",
      "AngularJS",
      "Bitbucket",
      "C#",
      "C++",
      "CSS",
      "Git",
      "Ionic",
      "HTML",
      "JAVA",
      "JavaScript",
      "Jenkins",
      "Jira",
      "Karma Testing",
      "Microsoft Suite",
      "Python",
      "Salesforce Development",
      "SQL Server",
      "Tableau Desktop",
      "Visual Basic",
    ]
  }

  public order(list: string[]): string[] {
    list.sort((stringA: string, stringB: string) => {
      if (stringA < stringB) {
        return -1;
      } else if (stringA > stringB) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }



}
